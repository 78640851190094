import React from "react";

import { useState } from "react";

import "../css/AddProfileModal.css";

function handleFocus(e) {
  e.target.type = "date";
}

function handleBlur(e) {
  if (!e.target.value) {
    e.target.type = "text";
  }
}

function AddProfileModal({ onClose, onProfileAdded }) {
  const [profileData, setProfileData] = useState({
    name: "",
    birth_date: "",
    injury: "",
    injured_leg: "",
    surgery_date: "",
    surgeon: "",
    start_date_center: "",
    weekly_sessions: "",
    athlete: false,
    addPredefinedObjectives: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProfileData({
      ...profileData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/profiles", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("userToken"),
        },
        body: JSON.stringify(profileData),
      });

      if (!response.ok) {
        window.alert("Error al añadir el perfil");
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (profileData.addPredefinedObjectives) {
        
      }

      onProfileAdded();
      onClose();
    } catch (error) {
      console.error("Error al añadir el perfil:", error);
    }
  };

  return (
    <div className="modal">
      <form className="modal-content-profile" onSubmit={handleSubmit}>
        <h2 className="modal-title">Añadir Perfil</h2>

        <div className="input-group-profile">
          <input
            type="text"
            name="name"
            className="input-field"
            placeholder="Nombre"
            value={profileData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-group-profile">
          <input
            type="text"
            name="birth_date"
            className="input-field"
            placeholder="Fecha de nacimiento"
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={profileData.birth_date}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-group-profile">
          <input
            type="text"
            name="injury"
            className="input-field"
            placeholder="Lesión"
            value={profileData.injury}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-group-profile">
          <select
            type="text"
            name="injured_leg"
            className="input-field"
            placeholder="Pierna"
            value={profileData.injured_leg}
            onChange={handleChange}
            required
          >
            <option value="">Pierna</option>
            <option value="Derecha">Derecha</option>
            <option value="Izquierda">Izquierda</option>
          </select>
        </div>

        <div className="input-group-profile">
          <input
            type="text"
            name="surgery_date"
            className="input-field"
            placeholder="Fecha de Intervención"
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={profileData.surgery_date}
            onChange={handleChange}
          />
        </div>

        <div className="input-group-profile">
          <input
            type="text"
            name="surgeon"
            className="input-field"
            placeholder="Cirujano"
            value={profileData.surgeon}
            onChange={handleChange}
          />
        </div>

        <div className="input-group-profile">
          <input
            type="text"
            name="start_date_center"
            className="input-field"
            placeholder="Inicio en Centro REF"
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={profileData.start_date_center}
            onChange={handleChange}
          />
        </div>

        <div className="input-group-profile">
          <input
            type="number"
            name="weekly_sessions"
            className="input-field"
            placeholder="Sesiones semanales"
            value={profileData.weekly_sessions}
            onChange={handleChange}
          />
        </div>

        <div className="checkbox-group-profile">
          <label>
            Deportista?
            <input
              type="checkbox"
              name="athlete"
              className="input-checkbox"
              checked={profileData.athlete}
              onChange={handleChange}
            />
          </label>
        </div>

        <div className="checkbox-group-profile">
          <label>
            Objetivos predefinidos
            <input
              type="checkbox"
              name="addPredefinedObjectives"
              checked={profileData.addPredefinedObjectives}
              onChange={handleChange}
            />
          </label>
        </div>

        <div className="modal-actions">
          <button type="button" className="button-cancel" onClick={onClose}>
            Cancelar
          </button>
          <button type="submit" className="button-save">
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddProfileModal;
