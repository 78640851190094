import React, { useState } from "react";
import PropTypes from "prop-types";

import "../css/AddObjectiveModal.css";

function AddObjectiveModal({ onClose, onSave }) {
  const [objective, setObjective] = useState("");

  const handleObjectiveChange = (e) => {
    setObjective(e.target.value);
  };

  const handleSaveObjective = () => {
    if (objective.trim() !== "") {
      onSave(objective);
      setObjective("");
      onClose();
    }
  };

  return (
    <div className="modal-background-objective">
      <form className="modal-content-objective" onSubmit={handleSaveObjective}>
        <h2>Añadir Objetivo</h2>
          <input
            type="text"
            placeholder="Escribe un objetivo"
            value={objective}
            onChange={handleObjectiveChange}
          />
          <div className="button-container-objective">
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancelar
            </button>
            <button type="submit" className="save-button" onClick={handleSaveObjective}>
              Guardar</button>
          </div>
        
      </form>
    </div>
  );
}

AddObjectiveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddObjectiveModal;
