import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BounceLoader } from 'react-spinners';

import "../css/Profile.css";
import ProgressBar from "../components/ProgressBar";

function Profile() {
  const { id } = useParams(); // Obtén el ID de la URL
  const [profileData, setProfileData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [originalProfileData, setOriginalProfileData] = useState(null);
  const [profileImage, setProfileImage] = useState("default-profile.png");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(
          `/api/profiles/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
        if (!response.ok) {
          window.alert("Error al recuperar los datos del perfil");
          throw new Error("Error al recuperar los datos del perfil");
        }
        const data = await response.json();
        setProfileData(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchPhaseCompletion = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`/api/phases/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          window.alert("Error al recuperar el estado de las fases");
          throw new Error("Error al recuperar el estado de las fases");
        }
        const phasesData = await response.json();

        // Calcular el progreso
        const completedPhasesCount = phasesData.filter(
          (phase) => phase.completed
        ).length;
        const totalPhases = phasesData.length;
        const progressPercentage =
          (completedPhasesCount / (totalPhases - 2)) * 100; //la fase 0 (preoperatorio) no cuenta

        setProgress(progressPercentage);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchProfileData();
    fetchPhaseCompletion();
  }, [id]);

  useEffect(() => {
    if (profileData && profileData.profile_picture) {
      setProfileImage(profileData.profile_picture);
    }
  }, [profileData]);

  if (!profileData) {
    return (
      <div className="loader-container">
        <BounceLoader color="#046b54" />
      </div>
    );
  }

  const handleEdit = () => {
    setOriginalProfileData(profileData);
    setIsEditing(true);
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que quieres eliminar este perfil?"
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(`/api/profiles/${id}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("userToken"),
        },
        method: "DELETE",
      });

      if (!response.ok) {
        window.alert("Error al eliminar el perfil");
        throw new Error("Error al eliminar el perfil");
      }

      navigate("/dashboard");
    } catch (error) {
      console.error("Error:", error);
      alert("Error al eliminar el perfil");
    }
  };

  const handleSaveEdit = async () => {
    const updatedProfileData = {
      ...profileData,
    };

    try {
      const response = await fetch(`/api/profiles/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("userToken"),
        },
        body: JSON.stringify(updatedProfileData),
      });

      if (!response.ok) {
        window.alert("Error al guardar los cambios del perfil");
        throw new Error("Error al guardar los cambios del perfil");
      }

      const updatedProfile = await response.json();
      setProfileData(updatedProfile);

      setIsEditing(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancelEdit = () => {
    setProfileData(originalProfileData);
    setOriginalProfileData(null);
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setProfileData({
      ...profileData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profile_picture", file);
        console.log(file);
      const response = await fetch(`/api/profiles/uploadPhoto/${id}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("userToken"),
        },
        method: "PUT",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setProfileImage(data.filePath); // Actualiza la ruta de la imagen después de subirla
      } else {
        console.error("Error al subir la imagen");
      }
    }
  };

  return (
    <div className="main">
      <div className="profile-container">
        <div className="profile-actions">
          {isEditing ? (
            <input
              type="text"
              name="name"
              value={profileData.name}
              onChange={handleInputChange}
            />
          ) : (
            <>
              <h1>{profileData.name}</h1>
              <div className="dropdown">
                <button className="dropbtn">
                  <i className="material-icons">more_vert</i>
                </button>
                <div className="dropdown-content">
                  <button onClick={handleEdit}>
                    <i className="material-icons">edit</i>
                  </button>
                  <button onClick={handleDelete}>
                    <i className="material-icons">delete</i>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <p className="profile-data">
          <span>Edad: </span>
          {isEditing ? (
            <input
              type="date"
              name="birth_date"
              className="profile-edit-input"
              value={profileData.birth_date}
              onChange={handleInputChange}
            />
          ) : (
            calculateAge(profileData.birth_date) +
            " (" +
            formatDate(profileData.birth_date) +
            ")"
          )}
        </p>

        <p className="profile-data">
          <span>Lesión: </span>
          {isEditing ? (
            <input
              type="text"
              name="injury"
              className="profile-edit-input"
              value={profileData.injury}
              onChange={handleInputChange}
            />
          ) : (
            profileData.injury
          )}
          <span> Pierna: </span>
          {isEditing ? (
            <select
              name="injured_leg"
              className="profile-edit-input"
              value={profileData.injured_leg}
              onChange={handleInputChange}
            >
              <option value="Derecha">Derecha</option>
              <option value="Izquierda">Izquierda</option>
            </select>
          ) : (
            profileData.injured_leg
          )}
        </p>
        <p className="profile-data">
          <span>Intervención: </span>
          {isEditing ? (
            <input
              type="date"
              name="surgery_date"
              className="profile-edit-input"
              value={profileData.surgery_date}
              onChange={handleInputChange}
            />
          ) : (
            formatDate(profileData.surgery_date)
          )}
          <span> Cirujano: </span>
          {isEditing ? (
            <input
              type="text"
              name="surgeon"
              className="profile-edit-input"
              value={profileData.surgeon}
              onChange={handleInputChange}
            />
          ) : (
            profileData.surgeon
          )}
        </p>
        <p className="profile-data">
          <span>Inicio en el centro:</span>
          {isEditing ? (
            <input
              type="date"
              name="start_date_center"
              className="profile-edit-input"
              value={profileData.start_date_center}
              onChange={handleInputChange}
            />
          ) : (
            formatDate(profileData.start_date_center)
          )}
          <span> Sesiones semanales: </span>
          {isEditing ? (
            <input
              type="number"
              name="weekly_sessions"
              className="profile-edit-input"
              value={profileData.weekly_sessions}
              onChange={handleInputChange}
            />
          ) : (
            profileData.weekly_sessions
          )}
        </p>
        <p className="profile-checkbox">
          <label>
            <span>Deportista</span>
          </label>
          {isEditing ? (
            <input
              type="checkbox"
              name="athlete"
              className="profile-edit-checkbox"
              checked={profileData.athlete}
              onChange={handleInputChange}
            />
          ) : (
            <input
              type="checkbox"
              name="athlete"
              className="profile-checkbox-static"
              checked={profileData.athlete}
              readOnly
            />
          )}
        </p>

        <div className="edit-actions">
          {isEditing && (
            <>
              <button className="button-edit-cancel" onClick={handleCancelEdit}>
                Cancelar
              </button>
              <button className="button-edit-save" onClick={handleSaveEdit}>
                Guardar
              </button>
            </>
          )}
        </div>

        <div>
          <h2>Fases</h2>
          <ul className="phases-list">
            <li>
              <button
                className="phase-button-pre"
                onClick={() => navigate(`/phase/${id}/0`)}
              >
                Preoperatorio
              </button>
            </li>
            <li>
              <button
                className="phase-button"
                onClick={() => navigate(`/phase/${id}/1`)}
              >
                1
              </button>
            </li>
            <li>
              <button
                className="phase-button"
                onClick={() => navigate(`/phase/${id}/2`)}
              >
                2
              </button>
            </li>
            <li>
              <button
                className="phase-button"
                onClick={() => navigate(`/phase/${id}/3`)}
              >
                3
              </button>
            </li>
            <li>
              <button
                className="phase-button"
                onClick={() => navigate(`/phase/${id}/4`)}
              >
                4
              </button>
            </li>
            {(profileData.athlete === 1 || profileData.athlete === true) 
            && (
              <li>
                <button
                  className="phase-button-pre"
                  onClick={() => navigate(`/phase/${id}/5`)}
                >
                  RTS
                </button>
              </li>
            )
            }
          </ul>
        </div>

        <ProgressBar progress={progress} />
      </div>
    </div>
  );
}

export default Profile;

function formatDate(dateString) {
  if (!dateString) return "";
  const parts = dateString.split("-");
  return `${parts[2]}-${parts[1]}-${parts[0]}`; // DD-MM-AAAA
}

function calculateAge(birthDate) {
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const m = today.getMonth() - birthDateObj.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  return age;
}
