import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/UserContext'; // Asegúrate de ajustar la ruta de importación según corresponda

import '../css/MenuBar.css';

function LogoutMenu() {
    const navigate = useNavigate();
    const { logoutUser } = useUserContext(); // Suponiendo que existe esta función en tu contexto

    const handleLogout = () => {
        logoutUser(); 
        navigate('/');
    };

    return (
        <ul className="menu-list">
            <li>
                <button onClick={handleLogout}><i className="material-icons">logout</i></button>
            </li>
            <li>
                <button onClick={() => navigate('/settings')}><i className="material-icons">settings</i></button>
            </li>
        </ul>
    );
}

export default LogoutMenu;
