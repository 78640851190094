import React, { useState, useEffect } from "react";
import "../css/Settings.css";

const Settings = () => {
  const [selectedPhase, setSelectedPhase] = useState('');
  const [objectiveText, setObjectiveText] = useState('');
  const [objectives, setObjectives] = useState([]);
  const [organizedObjectives, setOrganizedObjectives] = useState({});

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(
          `/api/predefinedObjectives`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          window.alert("Error al obtener los objetivos predefinidos");
          throw new Error("Error al obtener los objetivos predefinidos");
        }
        const data = await response.json();
        setObjectives(data);
        const organizedObjectives = organizeObjectivesByPhase(data);
        setOrganizedObjectives(organizedObjectives);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchObjectives();
  }, []);

  useEffect(() => {
    const organized = organizeObjectivesByPhase(objectives);
    setOrganizedObjectives(organized);
  }, [objectives]);

  const addObjective = async (phase, text) => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(
        "/api/predefinedObjectives",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            phase_number: phase,
            objective_text: text,
          }),
        }
      );
      if (!response.ok) {
        window.alert("Error al añadir el objetivo");
        throw new Error("Error al añadir el objetivo");
      }

      const data = await response.json();
      setObjectives([...objectives, data]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addObjective(selectedPhase, objectiveText);
    setSelectedPhase('');
    setObjectiveText('');
  };

  const handleDeleteObjective = async (id) => {
    const confirm = window.confirm(
      "¿Estás seguro de que deseas eliminar este trabajo realizado?"
    );
    if (!confirm) return;
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(
        `/api/predefinedObjectives/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        window.alert("Error al eliminar el objetivo");
        throw new Error("Error al eliminar el objetivo");
      }

      const updatedObjectives = objectives.filter(
        (objective) => objective.id !== id
      );

      setObjectives(updatedObjectives);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function organizeObjectivesByPhase(objectives) {
    const organized = {};

    objectives.forEach((objective) => {
      const phase = objective.phase_number;
      if (!organized[phase]) {
        organized[phase] = [];
      }
      organized[phase].push({
        id: objective.id,
        text: objective.objective_text,
      });
    });

    return organized;
  }
  return (
    <div className="main">
      <div className="settings-container">
        <h1 className="settings-header">Objetivos predefinidos</h1>

        <form onSubmit={handleSubmit} className="add-objective-form">
        <div>
          <label>Fase: </label>
          <select
            value={selectedPhase}
            onChange={(e) => setSelectedPhase(e.target.value)}
            required
          >
            <option value="">Seleccione una fase</option>
            {[0, 1, 2, 3, 4, 5].map((phase) => (
              <option key={phase} value={phase}>
                {phase === 0 ? "Preoperatorio" : phase === 5 ? "RTS" : `Fase ${phase}`}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Objetivo: </label>
          <input
            type="text"
            value={objectiveText}
            onChange={(e) => setObjectiveText(e.target.value)}
            placeholder="Introduce el objetivo"
            required
          />
        </div>
        <button type="submit">Añadir</button>
      </form>  

        {Object.entries(organizedObjectives).map(([phase, objectives]) => (
          <div key={phase}>
            <h3>
              {parseInt(phase) === 0 ? "Preoperatorio" : parseInt(phase) === 5 ? "RTS" : `Fase ${phase}`}
            </h3>
            <ul className="settings-list">
              {objectives.map((objective) => (
                <li key={objective.id} className="settings-item">
                  {objective.text}
                  <span className="remove-button">
                  <i
                    className="material-icons"
                    onClick={() => handleDeleteObjective(objective.id)}
                  >
                    remove
                  </i>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Settings;
