import React, { useState } from 'react';
import PropTypes from 'prop-types';

import '../css/AddContentModal.css';

function AddContentModal({ onClose, onSave }) {
  const [content, setContent] = useState('');

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleSaveContent = () => {
    if (content.trim() !== '') {
      onSave(content); 
      setContent(''); 
      onClose(); 
    }
  };

  return (
    <div className="modal-background-content">
      <form className="modal-content-content" onSubmit={handleSaveContent}>
        <h2>Añadir Contenido</h2>
        <input
          type="text"
          placeholder="Escribe un contenido"
          value={content}
          onChange={handleContentChange}
        />
        <div className="button-container-content">
          <button type='button' className="cancel-button" onClick={onClose}>
            Cancelar
          </button>
          <button type='submit' className="save-button" onClick={handleSaveContent}>
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
}

AddContentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddContentModal;
