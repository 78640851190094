import React, { useEffect} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import { BounceLoader } from 'react-spinners';

const PrivateRoute = ({ children }) => {
  const { isUserLoggedIn, verifyToken, loading, setLoading } = useUserContext();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      verifyToken(token);
    } else {
      setLoading(false); // Si no hay token, finaliza la carga
    }
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <BounceLoader color="#046b54" />
      </div>
    );
  }

  if (!isUserLoggedIn) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
