import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DonutChart from "../components/DonutChart";
import { BounceLoader } from 'react-spinners';

import AddSessionModal from "../components/AddSessionModal.jsx";
import AddObjectiveModal from "../components/AddObjectiveModal.jsx";
import AddContentModal from "../components/AddContentModal.jsx";
import "../css/Phase.css";

function Phase() {
  const { id, phase } = useParams();
  const navigate = useNavigate();

  const [phaseData, setPhaseData] = useState(null); // Estado para almacenar los datos de la fase
  const [sessions, setSessions] = useState([]); // Estado para las sesiones
  const [objectives, setObjectives] = useState([]);
  const [contents, setContents] = useState([]);
  const [profileData, setProfileData] = useState("");

  // Estado para controlar la visibilidad del modal
  const [isSessionModalOpen, setSessionModalOpen] = useState(false);
  const [isObjectiveModalOpen, setObjectiveModalOpen] = useState(false);
  const [isContentModalOpen, setContentModalOpen] = useState(false);

  const [isEditingStart, setIsEditingStart] = useState(false);
  const [editedStartDate, setEditedStartDate] = useState("");

  const [isEditingEnd, setIsEditingEnd] = useState(false);
  const [editedEndDate, setEditedEndDate] = useState("");

  useEffect(() => {
    const loadPhaseData = async () => {
      try {
        const token = localStorage.getItem("userToken");

        const phaseResponse = await fetch(
          `/api/phases/${id}/${phase}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const phaseData = await phaseResponse.json();

        const objectivesResponse = await fetch(
          `/api/phasesObjectives/${id}/${phase}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const objectivesData = await objectivesResponse.json();

        const contentsResponse = await fetch(
          `/api/phasesContents/${id}/${phase}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const contentsData = await contentsResponse.json();

        const sessionsResponse = await fetch(
          `/api/sessions/${id}/${phase}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sessionsData = await sessionsResponse.json();

        const nameProfileResponse = await fetch(
          `/api/profiles/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!nameProfileResponse.ok) {
          window.alert("Error al recuperar el nombre del perfil");
          throw new Error("Error al recuperar el nombre del perfil");
        }
        const profileData = await nameProfileResponse.json();

        setProfileData(profileData);
        setPhaseData(phaseData);
        setObjectives(Array.isArray(objectivesData) ? objectivesData : []);
        setContents(Array.isArray(contentsData) ? contentsData : []);
        setSessions(Array.isArray(sessionsData) ? sessionsData : []);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    loadPhaseData();
  }, [phase, id]);

  const updatePhaseCompleted = async (completed) => {
    try {
      const response = await fetch(
        `/api/phases/${id}/${phase}/completed`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
          body: JSON.stringify({ completed }),
        }
      );

      if (!response.ok) {
        window.alert("Error al actualizar el estado de completado de la fase");
        throw new Error(
          "Error al actualizar el estado de completado de la fase"
        );
      }
      // Aquí puedes actualizar el estado del frontend para reflejar el cambio
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const checkAndCompletePhase = async () => {
    if (!objectives.length || !phaseData) {
      //no se cargaron todavia
      return;
    }
    const allObjectivesAchieved = objectives.every((obj) => obj.achieved);
    const hasEndDate = !!phaseData.end_date;

    if (allObjectivesAchieved && hasEndDate && phase !== "0" && phase !== "5") {
      //fase 0 (preoperatorio) ni fase 5 (rts) se completan
      await updatePhaseCompleted(1);
    } else {
      await updatePhaseCompleted(0);
    }
  };

  // Cada vez que el estado 'objectives' cambie, se ejecutará la función checkAndCompletePhase
  useEffect(() => {
    checkAndCompletePhase();
  }, [objectives, phaseData]);

  const goToSession = (sessionId) => {
    navigate(`/sessions/${sessionId}`);
  };

  const toggleObjective = async (objectiveId, achieved) => {
    if (achieved === true) {
      achieved = 1;
    } else {
      achieved = 0;
    }
    try {
      const response = await fetch(
        `/api/phasesObjectives/${objectiveId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
          body: JSON.stringify({ achieved }), // Asegúrate de que el backend espera un campo 'completed'
        }
      );

      if (!response.ok) {
        window.alert("Error al actualizar el objetivo");
        throw new Error("Error al actualizar el objetivo");
      }

      // Actualiza el estado local de los objetivos para reflejar el cambio
      setObjectives((prevObjectives) =>
        prevObjectives.map((obj) =>
          obj.id === objectiveId ? { ...obj, achieved } : obj
        )
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Función para abrir el modal de sesión
  const openSessionModal = () => {
    setSessionModalOpen(true);
  };

  // Función para cerrar el modal de sesión
  const closeSessionModal = () => {
    setSessionModalOpen(false);
  };

  const openObjectiveModal = () => {
    setObjectiveModalOpen(true);
  };

  const closeObjectiveModal = () => {
    setObjectiveModalOpen(false);
  };

  const openContentModal = () => {
    setContentModalOpen(true);
  };

  const closeContentModal = () => {
    setContentModalOpen(false);
  };

  const handleSaveChanges = async () => {
    const updatedPhaseData = {
      ...phaseData,
      start_date: isEditingStart ? editedStartDate : phaseData.start_date,
      end_date: isEditingEnd ? editedEndDate : phaseData.end_date,
    };

    // Llamada a la función que realiza la solicitud PUT
    await updatePhaseData(updatedPhaseData);
    setIsEditingStart(false);
    setIsEditingEnd(false);
  };

  const updatePhaseData = async (data) => {
    try {
      const response = await fetch(
        `/api/phases/${id}/${phase}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        window.alert("Error al actualizar la fase");
        throw new Error("Error al actualizar la fase");
      }

      // Aquí podrías actualizar el estado con los nuevos datos
      setPhaseData(data);
      setIsEditingStart(false);
      setIsEditingEnd(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSaveObjective = async (objectiveText) => {
    try {
      const response = await fetch(
        `/api/phasesObjectives/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
          body: JSON.stringify({
            idprofile: id,
            phase_number: phase,
            objective: objectiveText,
          }),
        }
      );

      if (!response.ok) {
        window.alert("Error al guardar el objetivo");
        throw new Error("Error al guardar el objetivo");
      }

      await fetchUpdatedObjectives();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchUpdatedObjectives = async () => {
    try {
      const token = localStorage.getItem("userToken");

      const response = await fetch(
        `/api/phasesObjectives/${id}/${phase}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        window.alert("Error al obtener los objetivos actualizados");
        throw new Error("Error al obtener los objetivos actualizados");
      }
      const updatedObjectives = await response.json();
      setObjectives(updatedObjectives);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSaveContent = async (contentText) => {
    try {
      const response = await fetch(`/api/phasesContents`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
        body: JSON.stringify({
          idprofile: id,
          phase_number: phase,
          content: contentText,
        }),
      });

      if (!response.ok) {
        window.alert("Error al guardar el contenido");
        throw new Error("Error al guardar el contenido");
      }

      await fetchUpdatedContents();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchUpdatedContents = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(
        `/api/phasesContents/${id}/${phase}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        window.alert("Error al obtener los contenidos actualizados");
        throw new Error("Error al obtener los contenidos actualizados");
      }
      const updatedContents = await response.json();
      setContents(updatedContents);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSaveSession = async (sessionData) => {
    try {
      const sessionResponse = await fetch(
        "/api/sessions/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
          body: JSON.stringify({
            idprofile: id,
            phase_number: phase,
            observations: sessionData.observations,
            session_date: sessionData.date,
          }),
        }
      );

      if (!sessionResponse.ok) {
        window.alert("Error al guardar la sesión");
        throw new Error("Error al guardar la sesión");
      }

      const sessionResult = await sessionResponse.json();

      for (const exercise of sessionData.exercises) {
        await fetch("/api/sessionWorkDone", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
          body: JSON.stringify({
            idsession: sessionResult.idsession,
            work_done: exercise,
          }),
        });
      }

      for (const content of sessionData.contents) {
        await fetch("/api/sessionContents/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
          body: JSON.stringify({ idsession: sessionResult.idsession, content }),
        });
      }

      await fetchUpdatedSessions();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchUpdatedSessions = async () => {
    try {
      const token = localStorage.getItem("userToken");

      const response = await fetch(
        `/api/sessions/${id}/${phase}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedSessions = await response.json();
      setSessions(updatedSessions);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteObjective = async (objectiveId) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que quieres eliminar este objetivo?"
    );
    if (!confirmDelete) return;
    try {
      const response = await fetch(
        `/api/phasesObjectives/${objectiveId}`,
        {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("userToken"),
          },
          method: "DELETE",
        }
      );

      if (!response.ok) {
        window.alert("Error al eliminar el objetivo");
        throw new Error("Error al eliminar el objetivo");
      }

      await fetchUpdatedObjectives();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteContent = async (contentId) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que quieres eliminar este contenido?"
    );
    if (!confirmDelete) return;
    try {
      const response = await fetch(
        `/api/phasesContents/${contentId}`,
        {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("userToken"),
          },
          method: "DELETE",
        }
      );

      if (!response.ok) {
        window.alert("Error al eliminar el contenido");
        throw new Error("Error al eliminar el contenido");
      }

      await fetchUpdatedContents();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Datos para el gráfico de donut
  const totalObjectives = objectives.length;
  const completedObjectives = objectives.filter((obj) => obj.achieved).length;
  const completedPercentage =
    totalObjectives > 0 ? (completedObjectives / totalObjectives) * 100 : 0;
  const remainingPercentage = 100 - completedPercentage;

  const donutChartData = {
    datasets: [
      {
        data: [completedPercentage, remainingPercentage],
        backgroundColor: ["#4bc3a8", "#818181"],
      },
    ],
  };

  
  if (!phaseData) {
    return (
      <div className="loader-container">
        <BounceLoader color="#046b54" />
      </div>
    );
  }

  return (
    <div className="main">
      <div className="phase-container">
        <h1>{profileData.name}</h1>
        <h2>{phase === "0" ? "Preoperatorio" : "Fase " + phase}</h2>
        <div className="phase-details">
          <div className="phase-info">
            <div className="phase-data">
              Inicio:{" "}
              {isEditingStart ? (
                <input
                  type="date"
                  value={editedStartDate}
                  onChange={(e) => setEditedStartDate(e.target.value)}
                />
              ) : (
                formatDate(phaseData.start_date)
              )}
              {isEditingStart ? (
                <button
                  onClick={handleSaveChanges}
                  className="save-button-date"
                >
                  <i className="material-icons">save</i>
                </button>
              ) : (
                <button
                  onClick={() => setIsEditingStart(true)}
                  className="edit-button-date"
                >
                  <i className="material-icons">edit</i>
                </button>
              )}
            </div>

            <div className="phase-data">
              Final:{" "}
              {isEditingEnd ? (
                <input
                  type="date"
                  value={editedEndDate}
                  onChange={(e) => setEditedEndDate(e.target.value)}
                />
              ) : (
                formatDate(phaseData.end_date)
              )}
              {isEditingEnd ? (
                <button
                  onClick={handleSaveChanges}
                  className="save-button-date"
                >
                  <i className="material-icons">save</i>
                </button>
              ) : (
                <button
                  onClick={() => setIsEditingEnd(true)}
                  className="edit-button-date"
                >
                  <i className="material-icons">edit</i>
                </button>
              )}
            </div>

            <div className="objectives">
              <div className="objectives-header">
                <h3>Objetivos</h3>
                <button
                  className="add-button-objective"
                  onClick={openObjectiveModal}
                >
                  <i className="material-icons">add_task</i>
                </button>
              </div>
              <ul className="objectives-list">
                {objectives.map((objective, index) => (
                  <li key={index} className="objective-item">
                    <label>
                      {objective.objective}
                      <input
                        type="checkbox"
                        checked={objective.achieved}
                        onChange={() =>
                          toggleObjective(objective.id, !objective.achieved)
                        }
                      />
                    </label>
                    <span className="remove-button">
                      <i
                        className="material-icons"
                        onClick={() => handleDeleteObjective(objective.id)}
                      >
                        remove
                      </i>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="contents">
              <div className="contents-header">
                <h3>Contenidos</h3>
                <button
                  className="add-button-content"
                  onClick={openContentModal}
                >
                  <i className="material-icons">post_add</i>
                </button>
              </div>
              <ul className="objectives-list">
                {contents.map((content, index) => (
                  <li key={index}>
                    {content.content}
                    <span className="remove-button">
                      <i
                        className="material-icons"
                        onClick={() => handleDeleteContent(content.id)}
                      >
                        remove
                      </i>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="phase-progress">
            <div className="phase-sessions">
              <div className="phase-sessions-header">
                Sesiones
                <button
                  className="add-button-phase-session"
                  onClick={openSessionModal}
                >
                  <i className="material-icons">more_time</i>
                </button>
              </div>

              <div className="phase-sessions-list">
                {sessions.map((session) => (
                  <button
                    key={session.idsession}
                    onClick={() => goToSession(session.idsession)}
                  >
                    {formatDate(session.session_date)}
                  </button>
                ))}
              </div>
              <div className="phase-bar-progress">
                <DonutChart data={donutChartData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSessionModalOpen && (
        <AddSessionModal
          onClose={closeSessionModal}
          onSave={handleSaveSession}
        />
      )}
      {isObjectiveModalOpen && (
        <AddObjectiveModal
          onClose={closeObjectiveModal}
          onSave={handleSaveObjective}
        />
      )}
      {isContentModalOpen && (
        <AddContentModal
          onClose={closeContentModal}
          onSave={handleSaveContent}
        />
      )}
    </div>
  );
}

export default Phase;

function formatDate(dateString) {
  if (!dateString) return "";
  const parts = dateString.split("-");
  return `${parts[2]}-${parts[1]}-${parts[0]}`; // DD-MM-AAAA
}
