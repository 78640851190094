import React, { useState } from 'react';

import '../css/AddSessionModal.css';

function AddSessionModal({ onClose, onSave }) {
  const [sessionData, setSessionData] = useState({
    date: '',
    exercises: [''],
    contents: [''],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSessionData({
      ...sessionData,
      [name]: value,
    });
  };

  const handleAddExercise = () => {
    setSessionData({
      ...sessionData,
      exercises: [...sessionData.exercises, ''],
    });
  };

  const handleAddContent = () => {
    setSessionData({
      ...sessionData,
      contents: [...sessionData.contents, ''],
    });
  };

  const handleExerciseChange = (index, value) => {
    const updatedExercises = [...sessionData.exercises];
    updatedExercises[index] = value;
    setSessionData({
      ...sessionData,
      exercises: updatedExercises,
    });
  };

  const handleContentChange = (index, value) => {
    const updatedContents = [...sessionData.contents];
    updatedContents[index] = value;
    setSessionData({
      ...sessionData,
      contents: updatedContents,
    });
  };

  const handleSave = () => {
    const filteredExercises = sessionData.exercises.filter(exercise => exercise.trim() !== '');
    const filteredContents = sessionData.contents.filter(content => content.trim() !== '');
    
    const sessionDataToSave = {
      date: sessionData.date,
      exercises: filteredExercises,
      contents: filteredContents,
      observations: sessionData.observations,
  };

    if(sessionDataToSave.date && Date.parse(sessionDataToSave.date)) {
      onSave(sessionDataToSave); // Pasar datos a Phase.jsx
      onClose();
    } else {
      alert('Introduzca una fecha');
    }
  
  };

  return (
    <div className="modal">
      <form className="modal-content-add-session">
        <h2>Añadir Sesión</h2>
        <div className="form-group-date">
          <label htmlFor="date"></label>
          <input
            type="date"
            id="date"
            name="date"
            value={sessionData.date}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group-container">
        <div className="form-group list-container">
          <label>Ejercicios:</label>
          {sessionData.exercises.map((exercise, index) => (
            <input
              key={index}
              type="text"
              name={`exercise${index}`}
              value={exercise}
              className="list-item"
              onChange={(e) => handleExerciseChange(index, e.target.value)}
            />
          ))}
          <button type="button" className="add-button-session-modal" onClick={handleAddExercise}><i className='material-icons'>add</i></button>
        </div>

        <div className="form-group list-container">
          <label>Contenido:</label>
          {sessionData.contents.map((content, index) => (
            <input
              key={index}
              type="text"
              name={`content${index}`}
              value={content}
              className="list-item"
              onChange={(e) => handleContentChange(index, e.target.value)}
            />
          ))}
          <button type="button" className="add-button-session-modal" onClick={handleAddContent}><i className='material-icons'>add</i></button>
        </div>
        <textarea
          name="observations"
          placeholder="Observaciones"
          value={sessionData.observations}
          onChange={handleChange}
          rows="4"
          className="text-area"
        />
        </div>
        <div className="button-group-add-session">
          <button type="button" className="cancel-button" onClick={onClose}>Cancelar</button>
          <button type="submit" className='save-button' onClick={handleSave}>Guardar</button>
        </div>
      </form>
    </div>
  );
}

export default AddSessionModal;
