import React, { useState } from 'react';

import '../css/ProgressBar.css';

function ProgressBar({ progress }) {
 
  return (
    <div>
      <div className="progress-bar">
        <div
          className="progress"
          style={{ width: `${progress}%` }} // Establece el ancho dinámico
        ></div>
      </div>
      <h2>{progress}%</h2>
    </div>
  );
}

export default ProgressBar;
