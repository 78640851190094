import React, { useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";

import '../css/Login.css';
import logo from '../img/logo3.png';
import { UseUserContext, useUserContext } from '../context/UserContext.js';

function Login() {
  // Estados para manejar las entradas del usuario
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const { loginUser } = useUserContext();

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      await loginUser(username, password);
      navigate('/dashboard');
    } catch (error) {
      alert('Usuario o contraseña incorrectos');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2><img src={logo} alt="Centro REF" width={200}/></h2>
        
        <form onSubmit={handleSubmit}>
          <div className="input-group-login">
            <i className="icon user-icon"></i>
            <input
              type="text"
              placeholder="Usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group-login">
            <i className="icon lock-icon"></i>
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        
          <button type="submit" className="login-button">Login</button> 
        </form>
      </div>
    </div>
  );
}

export default Login;
