import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const loginUser = async (username, password) => {
    try {
      // Enviar una petición a tu backend para verificar las credenciales
      const response = await fetch('/api/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
  
      if (!response.ok) {
        window.alert('Error en el login');
        throw new Error('Error en el login');
      }
  
      const data = await response.json();
      
      localStorage.setItem('userToken', data.token);
      setIsUserLoggedIn(true);
    } catch (error) {
      throw error;
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('userToken');
    setIsUserLoggedIn(false);
  };

  const verifyToken = async (token) => {
    try {
      setLoading(true);
      const response = await fetch('/api/auth/verify', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        localStorage.removeItem('userToken');
        throw new Error('Token inválido');
      }

      setIsUserLoggedIn(true);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const value = { isUserLoggedIn, loginUser, logoutUser, verifyToken, loading, setLoading };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);