import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

function DonutChart({ data }) {
    const chartRef = useRef(null);
    let chartInstance = null;

    useEffect(() => {
        if (chartRef.current) {
            if (chartInstance) {
                chartInstance.destroy(); // Destruye el gráfico existente
            }

            const ctx = chartRef.current.getContext('2d');
            chartInstance = new Chart(ctx, {
                type: 'doughnut',
                data: data,
            });
        }

        return () => {
            if (chartInstance) {
                chartInstance.destroy(); // Asegura que se destruya el gráfico al desmontar el componente
            }
        };
    }, [data]);

    return (
        <div>
            <h3>Progreso</h3>
            <canvas ref={chartRef}></canvas>
        </div>
    );
}

export default DonutChart;
