import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";

import "../css/Session.css";

function Session() {
  const { idsession } = useParams();
  const [sessionData, setSessionData] = useState(null);
  const [sessionContents, setSessionContents] = useState([]);
  const [sessionWorkDone, setSessionWorkDone] = useState([]);
  const [sessionNumber, setSessionNumber] = useState(null);
  const [profileName, setProfileName] = useState("");
  const [video_url, setVideoUrl] = useState("");

  const [editableObservations, setEditableObservations] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [addingContent, setAddingContent] = useState(false);
  const [newContent, setNewContent] = useState("");
  const [addingWorkDone, setAddingWorkDone] = useState(false);
  const [newWorkDone, setNewWorkDone] = useState("");
  const [isEditingVideo, setIsEditingVideo] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(
          `/api/sessions/${idsession}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          window.alert("Error al recuperar los datos de la sesión");
          throw new Error("Error al recuperar los datos de la sesión");
        }
        const dataSession = await response.json();

        const contentsResponse = await fetch(
          `/api/sessionContents/all/${idsession}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!contentsResponse.ok) {
          window.alert("Error al recuperar los contenidos de la sesión");
          throw new Error("Error al recuperar los contenidos de la sesión");
        }
        const contentsData = await contentsResponse.json();

        const workDoneResponse = await fetch(
          `/api/sessionWorkDone/all/${idsession}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!workDoneResponse.ok) {
          window.alert("Error al recuperar el trabajo realizado en la sesión");
          throw new Error(
            "Error al recuperar el trabajo realizado en la sesión"
          );
        }
        const workDoneData = await workDoneResponse.json();

        const sessionNumberResponse = await fetch(
          `/api/sessions/${dataSession.idprofile}/${dataSession.phase_number}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!sessionNumberResponse.ok) {
          window.alert("Error al recuperar el número de sesión");
          throw new Error("Error al recuperar el número de sesión");
        }
        const sessionNumberData = await sessionNumberResponse.json();
        const orderedSessions = sessionNumberData.sort(
          (a, b) => new Date(a.session_date) - new Date(b.session_date)
        );
        const sessionIndex = orderedSessions.findIndex(
          (session) => session.idsession === parseInt(idsession)
        );

        const nameProfileResponse = await fetch(
          `/api/profiles/${dataSession.idprofile}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!nameProfileResponse.ok) {
          window.alert("Error al recuperar el nombre del perfil");
          throw new Error("Error al recuperar el nombre del perfil");
        }
        const profileData = await nameProfileResponse.json();

        setProfileName(profileData.name);
        setSessionNumber(sessionIndex + 1);
        setSessionWorkDone(workDoneData);
        setSessionContents(contentsData);
        setSessionData(dataSession);
        setVideoUrl(dataSession.video_url);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchSessionData();
  }, [idsession]);

  useEffect(() => {
    if (sessionData) {
      setEditableObservations(sessionData.observations);
    }
  }, [sessionData]);

  const handleObservationsChange = (e) => {
    setEditableObservations(e.target.value);
  };

  const handleDeleteWorkItem = async (id) => {
    const confirm = window.confirm(
      "¿Estás seguro de que deseas eliminar este trabajo realizado?"
    );
    if (!confirm) return;
    try {
      const response = await fetch(
        `/api/sessionWorkDone/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setSessionWorkDone(sessionWorkDone.filter((item) => item.id !== id));
    } catch (error) {
      window.alert("Error al eliminar el trabajo realizado");
      console.error("Error al eliminar el trabajo realizado:", error);
    }
  };

  const handleDeleteContent = async (id) => {
    const confirm = window.confirm(
      "¿Estás seguro de que deseas eliminar este contenido?"
    );
    if (!confirm) return;
    try {
      const response = await fetch(
        `/api/sessionContents/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setSessionContents(sessionContents.filter((item) => item.id !== id));
    } catch (error) {
      window.alert("Error al eliminar el contenido");
      console.error("Error al eliminar el contenido:", error);
    }
  };

  const handleEditObservations = () => {
    setIsEditing(true);
  };

  const handleSaveObservations = async () => {
    const response = await fetch(
      `/api/sessions/${sessionData.idsession}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          observations: editableObservations,
        }),
      }
    );

    if (!response.ok) {
      window.alert("Error al guardar las observaciones");
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    setIsEditing(false);
    setSessionData({ ...sessionData, observations: editableObservations });
  };

  const handleSaveNewContent = async () => {
    if (!newContent.trim()) return;

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(
        `/api/sessionContents`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            idsession: sessionData.idsession,
            content: newContent,
          }),
        }
      );

      if (response.ok) {
        const addedContent = await response.json();
        setSessionContents([addedContent, ...sessionContents]);
        setAddingContent(false);
        setNewContent("");
      } else {
        throw new Error("Error al guardar el contenido");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error al guardar el nuevo contenido");
    }
  };

  const handleSaveNewWorkDone = async () => {
    if (!newWorkDone.trim()) return;
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(
        `/api/sessionWorkDone`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            idsession: sessionData.idsession,
            work_done: newWorkDone,
          }),
        }
      );

      if (response.ok) {
        const addedWorkDone = await response.json();
        setSessionWorkDone([addedWorkDone, ...sessionWorkDone]);
        setAddingWorkDone(false);
        setNewWorkDone("");
      } else {
        throw new Error("Error al guardar el trabajo realizado");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error al guardar el nuevo trabajo realizado");
    }
  };

  const handleEditVideo = () => {
    setIsEditingVideo(true);
  };

  const handleSaveVideo = async () => {
    let urlToSend;
    if(video_url === null || video_url.trim() === "") {
      urlToSend = null;
    }else if (!isValidUrl(video_url)) {
      alert("Por favor, ingresa una URL válida.");
      setIsEditingVideo(true);
      return;
    } else {
      urlToSend = video_url;
    }
    setIsEditingVideo(false);
    try {
      const response = await fetch(
        `/api/sessions/${sessionData.idsession}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify({ ...sessionData, video_url: urlToSend }),
        }
      );
      if (response.ok) {
        setSessionData({ ...sessionData, video_url: urlToSend });
      } else {
        alert("Error al guardar la URL del video.");
        setIsEditingVideo(true);
      }
    } catch (error) {
      console.error("Error al guardar la URL del video:", error);
      setIsEditingVideo(true);
    }
  };

  const handleDeleteSession = async (sessionId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar esta sesión?")) {
      try {
        const response = await fetch(
          `/api/sessions/${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
            method: "DELETE",
          }
        );

        if (!response.ok) {
          window.alert("Error al eliminar la sesión");
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log("Sesión eliminada correctamente");
        navigate(
          "/phase/" + sessionData.idprofile + "/" + sessionData.phase_number
        );
      } catch (error) {
        console.error("Error al eliminar la sesión:", error);
      }
    }
  };

  if (!sessionData || !sessionContents || !sessionWorkDone) {
    return (
      <div className="loader-container">
        <BounceLoader color="#046b54" />
      </div>
    );
  }

  return (
    <div className="main">
      <div className="session-container">
        <h1>{profileName}</h1>
        <div className="session-header">
          <div className="session-title">
            <h2>
              Fase {sessionData.phase_number} - Sesión {sessionNumber}
            </h2>
          </div>
          <i
            className="material-icons delete-icon"
            onClick={() => handleDeleteSession(sessionData.idsession)}
          >
            delete
          </i>
        </div>
        <div className="session-details">
          <div className="session-info">
            <div className="session-data">
              <div className="date-text">
                <span>Fecha: </span>
                {formatDate(sessionData.session_date)}
              </div>
            </div>
            <div className="session-exercises-data">
              <div className="exercises-header">
                <h3 className="exercises-title">Trabajo realizado</h3>
                <button className="add-button-objective">
                  <i
                    className="material-icons"
                    onClick={() => setAddingWorkDone(true)}
                    style={{ cursor: "pointer" }}
                  >
                    add_task
                  </i>
                </button>
              </div>
              <ul className="session-list">
                {addingWorkDone && (
                  <li className="session-item-add">
                    <input
                      type="text"
                      value={newWorkDone}
                      onChange={(e) => setNewWorkDone(e.target.value)}
                      placeholder="Nuevo trabajo realizado"
                    />
                    <i
                      className="material-icons"
                      onClick={handleSaveNewWorkDone}
                      style={{ cursor: "pointer" }}
                    >
                      add
                    </i>
                    <i
                      className="material-icons"
                      onClick={() => setAddingWorkDone(false)}
                      style={{ cursor: "pointer" }}
                    >
                      remove
                    </i>
                  </li>
                )}
                {sessionWorkDone.map((workItem, index) => (
                  <li key={index} className="session-item">
                    {workItem.work_done}
                    <span className="remove-button">
                      <i
                        className="material-icons"
                        onClick={() => handleDeleteWorkItem(workItem.id)}
                      >
                        remove
                      </i>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="session-exercises-data">
              <div className="exercises-header">
                <h3 className="exercises-title">Contenido</h3>
                <button className="add-button-objective">
                  <i
                    className="material-icons"
                    onClick={() => setAddingContent(true)}
                    style={{ cursor: "pointer" }}
                  >
                    post_add
                  </i>
                </button>
              </div>
              <ul className="session-list">
                {addingContent && (
                  <li className="session-item-add">
                    <input
                      type="text"
                      value={newContent}
                      onChange={(e) => setNewContent(e.target.value)}
                      placeholder="Nuevo contenido"
                    />
                    <i
                      className="material-icons"
                      onClick={handleSaveNewContent}
                      style={{ cursor: "pointer" }}
                    >
                      add
                    </i>
                    <i
                      className="material-icons"
                      onClick={() => setAddingContent(false)}
                      style={{ cursor: "pointer" }}
                    >
                      remove
                    </i>
                  </li>
                )}
                {sessionContents.map((contentItem, index) => (
                  <li key={index} className="session-item">
                    {contentItem.content}
                    <span className="remove-button">
                      <i
                        className="material-icons"
                        onClick={() => handleDeleteContent(contentItem.id)}
                      >
                        remove
                      </i>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="session-observation">
            <div className="observation-header">
              {" "}
              Observaciones{" "}
              {!isEditing && (
                <i className="material-icons" onClick={handleEditObservations}>
                  edit
                </i>
              )}
              {isEditing && (
                <i className="material-icons" onClick={handleSaveObservations}>
                  save
                </i>
              )}
            </div>

            <textarea
              name="observation"
              id="observation"
              cols="30"
              rows="10"
              value={editableObservations}
              onChange={(e) => setEditableObservations(e.target.value)}
              disabled={!isEditing}
            ></textarea>

            
          </div>
        </div>
        <div className="video-container">
              {isEditingVideo ? (
                <>
                  <input
                    type="text"
                    value={video_url}
                    onChange={(e) => setVideoUrl(e.target.value)}
                  />
                  <button className="add-button-objective" onClick={handleSaveVideo}>Guardar</button>
                </>
              ) : (
                <>
                <div>
                  <button className="add-button-objective" onClick={handleEditVideo}>Enlace Youtube</button>
                </div>
                  {video_url && (
                    <iframe
                    width="600"
                    height="400"
                    src={getEmbedUrl(video_url)}
                    title="YouTube video player"
                    
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                  </iframe>
                  )}
                  
                </>
              )}
            </div>
      </div>
    </div>
  );
}

export default Session;

function formatDate(dateString) {
  if (!dateString) return dateString;
  const parts = dateString.split("-");
  return `${parts[2]}-${parts[1]}-${parts[0]}`; // DD-MM-AAAA
}

const getEmbedUrl = (url) => {
  const urlObj = new URL(url);
  const videoId = urlObj.searchParams.get("v");
  return `https://www.youtube.com/embed/${videoId}`;
}

const isValidUrl = (urlString) => {
  if (urlString === "") return true;
  try {
      new URL(urlString);
      return true;
  } catch (e) {
      return false;
  }
};