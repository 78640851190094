import './css/App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { UserProvider, useUserContext } from './context/UserContext.js';

import Login from './pages/Login.jsx';
import Dashboard from './pages/Dashboard.jsx';
import Profile from './pages/Profile.jsx';
import Phase from './pages/Phase.jsx';
import Session from './pages/Session.jsx';
import MenuBar from './components/MenuBar.jsx';
import LogoutMenu from './components/LogoutMenu.jsx';
import PrivateRoute from './components/PrivateRoute.jsx';
import Settings from './pages/Settings.jsx';

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<PrivateLayout />} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;

function PrivateLayout() {

  const location = useLocation();
  const showLogoutMenu = location.pathname !== "/dashboard";

  return (
    <>
      {showLogoutMenu ? <MenuBar /> : <LogoutMenu />}
      <Routes>
      <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
      <Route path="/profile/:id" element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />
        <Route path="/phase/:id/:phase" element={
            <PrivateRoute>
              <Phase />
            </PrivateRoute>
          } />
        <Route path="/sessions/:idsession" element={
            <PrivateRoute>
              <Session />
            </PrivateRoute>
          } />
        <Route path="/settings" element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          } />
      </Routes>
    </>
  );
}

