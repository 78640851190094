import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../css/Dashboard.css';
import logo from '../img/logo3.png';
import AddProfileModal from "../components/AddProfileModal.jsx"

function Dashboard() {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchProfiles = () => {
    
    const token = localStorage.getItem('userToken');
  
    fetch('/api/profiles', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      if (!response.ok) {
        window.alert('Error en la red');
        throw new Error('Error en la red');
      }
      return response.json();
    })
    .then(data => setProfiles(data))
    .catch(error => console.error('Error al obtener los perfiles:', error));
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  // Estado para mantener un seguimiento de las personas seleccionadas
  const [selectedPeople, setSelectedPeople] = useState([]);

  // Función para manejar la selección de personas y redirigir al usuario
  const selectPersonAndRedirect = (profile) => {
    // Redirige al usuario a otro enlace una vez seleccionada la persona
    navigate(`/profile/${profile.idprofile}`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredProfiles = profiles.filter(profile =>
    profile.name.toLowerCase().includes(searchTerm)
  );
  

  return (
    <div className='main'>
      <div className="dashboard-container">
      <h2><img src={logo} alt="Centro REF" width={200}/></h2>
        <div className='search-container'>
          <input
            type='text'
            placeholder='Buscar'
            onChange={handleSearchChange}
          />
          <button className='add-button-dashboard' onClick={openModal}><i className="material-icons">person_add</i></button>
        </div>
        <div className="people-list">
          {filteredProfiles.map((profile) => (
            <div
              key={profile.idprofile}
              className={`person-item ${selectedPeople.includes(profile) ? 'selected' : ''}`}
              onClick={() => selectPersonAndRedirect(profile)}
            >
              {profile.name}
            </div>
          ))}
        </div>
      </div>
      {/* Renderiza el modal si isModalOpen es true */}
      {isModalOpen && <AddProfileModal onClose={closeModal} onProfileAdded={fetchProfiles}/>}
    </div>
  );
}

export default Dashboard;
