import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import '../css/MenuBar.css';

function MenuBar() {

    const navigate = useNavigate();
    const location = useLocation();

    const irAtras = () => {
        if (location.pathname.includes('/profile')) {
            navigate('/dashboard');
        } else if (location.pathname.includes('/phase')) {
            // /phase/:id/:phase
            const profileId = location.pathname.split('/')[2];
            navigate(`/profile/${profileId}`);
        } else if (location.pathname.includes('/dashboard')) {
            navigate('/dashboard');
        }
        else {
            navigate(-1);
        }
    };

    return (
        <ul className="menu-list">
            <li className='backOption'>
                <button onClick={irAtras}><i className="material-icons">arrow_back</i></button>
            </li>
            <li className='homeOption'>
                <Link to="/dashboard"><i className="material-icons">home</i></Link>
            </li>
        </ul>
    );
}

export default MenuBar;
